import moment from "moment"
import { getValidationSchema as getTaskValidationSchema } from "pages/CreateUpdateTask/FormContent"
import * as Yup from "yup"

const getValidationSchema = (freeFormNames: boolean) => {
  return Yup.object().shape({
    opportunity: Yup.object().shape({
      designWinDetail: Yup.string().nullable(),
      workstreamName: Yup.string().required("You must specify opportunity type."),
      accountId: Yup.string().required("You must specify a partner name or spms id."),
      title: Yup.string()
        .required("You must specify title.")
        .max(90, "Opportunity name can't be more than 90 characters.")
        .matches(/^[^\\]*$/, "Backslash is not allowed."),
      region: Yup.string()
        .nullable()
        .test({
          name: "region-required",
          test: function (value) {
            return freeFormNames ? true : Boolean(value)
          },
          message: "You must specify region.",
        }),
      geo: Yup.string()
        .nullable()
        .test({
          name: "geo-required",
          test: function (value) {
            return freeFormNames ? true : Boolean(value)
          },
          message: "You must specify geography.",
        }),
      nextStep: Yup.string()
        .required("You must specify next step.")
        .max(255, "Next step can't be more than 255 characters.")
        .when(["workstreamName", "stageName"], {
          is: (workstreamName: string, stageName: string) => workstreamName === "Foundation Planning" && stageName === "Completed",
          then: (schema) =>
            schema.matches(
              /^https:\/\/amazon.awsapps.com\/workdocs|^https:\/\/quip-amazon.com|^https:\/\/apn-portal.lightning.force.com\/lightning\/r\/Partner_Business_Plan__c/i,
              "Next Step must be a URL beginning with https://amazon.awsapps.com/workdocs or https://quip-amazon.com or https://apn-portal.lightning.force.com/lightning/r/Partner_Business_Plan__c"
            ),
        })
        .when(["workstreamName", "stageName"], {
          is: (workstreamName: string, stageName: string) => workstreamName === "Service Delivery Program" && stageName === "Completed",
          then: (schema) =>
            schema.matches(
              /^SD-\d{7}$|^App-\d{7}$/i,
              "Next Step must be completed ID (App-ID or SD-ID) i.e. App-1234567 or SD-1234567 to show that the APN designation is confirmed. These can be found in APN SFDC"
            ),
        })
        .when(["workstreamName", "stageName"], {
          is: (workstreamName: string, stageName: string) => workstreamName === "Partner Competency" && stageName === "Completed",
          then: (schema) =>
            schema.matches(
              /^C-\d{4,5}$|^App-\d{7}$/i,
              "Next Step must be completed ID (App-ID or C-ID) i.e. App-1234567 or C-12345 to show that the APN designation is confirmed. These can be found in APN SFDC"
            ),
        })
        .when(["workstreamName", "stageName"], {
          is: (workstreamName: string, stageName: string) =>
            (workstreamName === "Well Architected Partner Program" ||
              workstreamName === "Immersion Day Partner Program" ||
              workstreamName === "Managed Service Provider" ||
              workstreamName === "AWS Public Sector Partner" ||
              workstreamName === "Authority to Operate on AWS" ||
              workstreamName === "Solution Provider Program" ||
              workstreamName === "ProServe Ready" ||
              workstreamName === "AWS Marketplace Skilled Consulting Partner") &&
            stageName === "Completed",
          then: (schema) =>
            schema.matches(
              /^P-\d{4,5}$|^App-\d{7}$/i,
              "Next Step must be completed ID (App-ID or P-ID) i.e. App-1234567 or P-12345 to show that the APN designation is confirmed. These can be found in APN SFDC"
            ),
        })
        .matches(/^[^\\]*$/, "Backslash is not allowed."),
      description: Yup.string()
        .max(32000, "Description can't be more than 32000 characters.")
        .required("You must specify description.")
        .matches(/^[^\\]*$/, "Backslash is not allowed."),
      closeDate: Yup.date()
        .required("You must specify close date.")
        .typeError("You must specify date in the format YYYY/MM/DD")
        .when("stageName", {
          is: (val: string) => val === "Completed" || val === "Closed Lost",
          then: (schema) =>
            schema
              .min("2020/01/01", "You must specify date greater than 2020/01/01")
              .max(moment().add(60, "days"), "You must specify date within 60 days from today"),
          otherwise: (schema) => schema.min(moment(), "Close date must be set to future date if Stage is not Completed or Closed Lost."),
        }),
      stageName: Yup.string().required("You must specify stage."),
      opportunityLineItems: Yup.array().when(["workstreamName", "stageName", "designWinDetail"], {
        is: (workstreamName: string, stageName: string, designWinDetail: string) =>
          workstreamName === "[Design Win] Partner Offering" && stageName === "Completed" && designWinDetail !== "Marketplace Listing",
        then: (schema) => schema.required("You must specify at least one opp line item").min(1),
        otherwise: (schema) => schema.optional(),
      }),
      externalLink: Yup.string()
        .nullable()
        .when(["workstreamName", "stageName", "designWinDetail"], {
          is: (workstreamName: string, stageName: string, designWinDetail: string) =>
            workstreamName === "[Design Win] Partner Offering" && stageName === "Completed" && designWinDetail === "Marketplace Listing",
          then: (schema) =>
            schema
              .required("External Link required when MP Solution")
              .matches(/^https:\/\/aws.amazon.com\/marketplace\//i, "For MP Solutions, external link must start with: https://aws.amazon.com/marketplace/")
              .url("External Link needs to be a valid URL"),
          otherwise: (schema) => schema.optional().url("External Link needs to be a valid URL"),
        }),
    }),
    tasks: Yup.array().of(getTaskValidationSchema(false, freeFormNames)),
  })
}

export default getValidationSchema
